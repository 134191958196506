.about-header {
  text-align: center;
  font-size: 50px;
  margin-block-start: 20px;
  margin-block-end: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.about-text {
  margin-left: 15%;
  margin-right: 15%;
  font-size: 20px;
  text-indent: 50px;
}

.about-text,
.bio-text {
  line-height: 1.4;
}

.bios-header {
  text-align: center;
  font-size: 40px;
  margin-left: 50px;
  margin-right: 50px;
  margin-block-start: 20px;
  margin-block-end: 20px;
}

.source-text {
  text-align: center;
  font-size: 20px;
  margin-left: 50px;
  margin-right: 50px;
}

.source-text a {
  text-decoration: none;
  color: #fdc500;
}

.source-text a:hover {
  color: #fff;
  text-decoration: underline;
}

.headshot {
  border-radius: 100%;
}

.bios-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  place-content: center;
  gap: 0.5rem;
  padding-left: 5%;
  padding-right: 5%;
}

.bio-text {
  padding-left: 10%;
  padding-right: 10%;
}

.bio-name {
  font-size: 20px;
  padding-left: 10%;
  padding-right: 10%;
  margin-block-start: 0.25em;
  margin-block-end: 0.25em;
}

.title-text {
  font-size: 18px;
  color: #fdc500;
  margin-block-start: 0.25em;
  margin-block-end: 0.25em;
}

.icon {
  padding: 10px;
  transition: filter 1s;
}

.icon:hover {
  filter: drop-shadow(0px 0px 15px #fdc500);
  transition: filter 1s;
}

.demo-video-container {
  text-align: center;
}

.demo-video-frame {
  aspect-ratio: 16 / 9;
  width: 70%;
}

@media screen and (max-width: 1024px) {
  .bios-section {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0.5rem;
    place-content: center;
  }
  .demo-video-frame {
    width: 80%;
  }
}

@media screen and (max-width: 690px) {
  .bios-section {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0.5rem;
    place-content: center;
  }
  .about-header {
    font-size: 40px;
  }
  .about-text {
    margin-left: 5%;
    margin-right: 5%;
  }
  .demo-video-frame {
    width: 90%;
  }
}
