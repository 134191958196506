.home-container {
  text-align: center;
  margin-left: 50px;
  margin-right: 50px;
  padding: 2%;
  background-color: #051a42;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5%;
  justify-content: center;
}

.home-text span {
  color: #f5d332;
  font-weight: bold;
}

.quote {
  font-style: italic;
  font-weight: bold;
  color: #f4c745;
}

.home-container img {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 10%;
}

.home-subcontainer {
  width: 100%;
  padding: 5%;
}

.home-header {
  font-size: 50px;
  margin-block-start: 20px;
  margin-block-end: 20px;
}

.home-tagline {
  font-size: 35px;
}

@media screen and (max-width: 690px) {
  .home-container {
    grid-template-columns: repeat(1, 1fr);
    margin-left: 25px;
    margin-right: 25px;
    gap: 0%;
  }
  .home-subcontainer {
    margin-top: 0%;
    padding: 0%;
  }
  .home-container img {
    margin-top: 0%;
  }
}
