.drink-header {
  text-align: center;
  font-size: xxx-large;
}

.alcohol-list {
  margin: 2em 2em;
}

.alcohol-list img {
  width: 60%;
  height: auto;
}