.footer {
  margin-top: 20px;
  padding: 1rem;
  background-color: #001b46;
  bottom: 0;
  left: 0;
  text-align: center;
}

.footer-text {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  margin: 10px;
}

@media (max-width: 767px) {
  .footer-text {
    font-size: 15px;
  }
}
