.favorite-page {
    text-align: center;
    justify-content: center;
}

.favorites-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    margin: 2em 2em;
    justify-content: center;
    justify-items: center;
}

.favorites-cell {
    width: 325px;
    text-align: center;
    box-shadow: 4px 4px 4px #061a43;
    border-radius: 15px;
    padding: 5px;
}

.favorites-container img {
    height: 200px;
    width: auto;
    max-width: 250px;
}

.favorite-name {
    font-size: 30px;
}

.favorites-container a {
    text-decoration: none;
}

.favorite-name:hover {
    text-decoration: underline;
}

@media screen and (min-width: 1100px) and (max-width: 1439px) {
    .favorites-container {
        grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 768px) and (max-width: 1099px) {
    .favorites-container {
        grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 767px) {
    .favorites-container {
        grid-template-columns: repeat(1, 1fr);
        margin: 2em 0.5em;
  }
}
