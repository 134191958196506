.error-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.error-headings {
  text-align: center;
}

.error-title {
  font-size: 70px;
  margin-block-start: 10px;
  margin-block-end: 10px;
}

.error-tagline {
  font-size: 30px;
  margin-block-start: 10px;
  margin-block-end: 10px;
}

.error-text {
  text-align: center;
  font-size: 30px;
  margin-left: 10%;
    margin-right: 10%;
  grid-area: 1 / 2 / 2 / 3;
}

.error-image {
  grid-area: 1 / 1 / 2 / 2;
  text-align: center;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.closed-bar {
  height: 500px;
}

@media screen and (min-width: 1100px) and (max-width: 1439px) {
  .closed-bar {
    height: 400px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1099px) {
  .closed-bar {
    height: 300px;
  }
}

@media screen and (max-width: 767px) {
  .error-title {
    font-size: 50px;
    margin-left: 5%;
    margin-right: 5%;
  }
  .error-tagline {
    font-size: 25px;
    margin-left: 5%;
    margin-right: 5%;
  }
  .error-text {
    font-size: 25px;
    margin-left: 5%;
    margin-right: 5%;
  }
  .error-grid {
    display: block;
  }
  .closed-bar {
    height: 250px;
  }
}
