.landing-container {
  text-align: center;
}

.image-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

}

.image-container img {
  width: 70%;
  height: 80%;
}

@media (max-width: 585px) {
  .image-container img {
    margin-top: 2%;

  }
  .image-container a {
    margin-bottom: 20px;
  }
  .image-container button {
    font-size: 15px !important;
  }
}

@media (max-width: 684px) {
  .image-container {
    grid-template-columns: 1fr
  
  }
}