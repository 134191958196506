::selection {
  background: #fdc500;
  color: #00296b;
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 0.75em;
}

::-webkit-scrollbar-track {
  background: #001b46;
}

::-webkit-scrollbar-thumb {
  background: #fdc500;
}

::-webkit-scrollbar-thumb:active {
  background: #927200;
}

body {
  background-color: #00296b;
  color: #ffffff;
  padding-top: 100px;
}

a {
  color: #ffffff;
  transition: color 0.5s;
}

a:hover {
  color: #fdc500;
  transition: color 0.5s;
}

h1 {
  color: #fdc500;
}

@media screen and (max-width: 690px) {
  body {
    padding-top: 175px;
  }
}

/* Button colors and sizes */

button,
input[type="submit"] {
  background-color: #fdc500;
  border: none;
  color: #00296b;
  font-weight: bold;
  padding: 15px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.5s;
}

input[type="submit"] {
  background-color: #00e11a;
}

button:hover,
input[type="submit"]:hover {
  background-color: #e7e7e7;
  color: black;
  transition: background-color 0.5s;
}

.large-button {
  font-size: 20px;
}

/* Text Inputs */

label {
  padding: 10px;
}

input,
textarea {
  color: #ffffff;
  background-color: #001b46;
  font-size: 16px;
}

textarea {
  vertical-align: top;
  font-family: Arial, Helvetica, sans-serif;
}

input {
  margin-bottom: 10px;
  margin-top: 10px;
}

input[type="text"],
input[type="url"],
input[type="number"],
textarea {
  padding: 12px 12px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.checkmark {
  accent-color: #fdc500;
}
