#hidden {
  display: none;
}

.onboarding {
  text-align: center;
  margin-left: 15%;
  margin-right: 15%;
}

.info-wrap-onboarding {
  border: 1px solid #f3c743;
  border-radius: 15px;
  background-color: #061a43;
  text-align: center;
  margin: auto;
  margin-top: 3%;
  margin-bottom: 3%;
  width: fit-content;
  padding: 3%;
}

.input-label-wrap-onboarding {
  display: flex;
  justify-content: center;
}

.input-label-wrap-onboarding label {
  display: inline-block;
  min-width: 25%;
  font-weight: bold;
}

.onboarding-label {
  font-weight: bold;
}

.input-label-wrap-onboarding input {
  border-radius: 5px;
  width: 50%;
  margin-left: 7%;
  margin-right: 7%;
}

#flavors:hover {
  cursor: pointer;
}
