.sign-up-section {
  text-align: center;
  justify-content: center;
  display: flex;
}

.sign-up-container {
  border: 1px solid #f3c743;
  border-radius: 15px;
  background-color: #061a43;
  width: 30%;
  padding: 3%;
  margin: 3%;
}

.input-label-wrap {
  display: flex;
  justify-content: center;
}

.sign-up-section label {
  display: inline-block;
  width: 25%;
}

.sign-up-section input {
  border-radius: 5px;
  width: 50%;
  margin-left: 7%;
  margin-right: 7%;
}

@media screen and (max-width: 1024px) {
  .sign-up-container {
    width: 50%;
  }
}

@media screen and (max-width: 690px) {
  .sign-up-container {
    width: 80%;
  }
}
