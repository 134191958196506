#establishment-back-button {
  position: absolute;
  left: 10%;
  top: 15%;
}

.second-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  justify-items: center;
  text-align: center;
  column-gap: 5px;
}

#review-section {
  text-align: center;
  margin-left: 2%;
  padding-left: 5%;
  padding-right: 5%;
  border: 10px solid #051A42;
  background-color: #051A42;
}

#review-section span {
  color: #F4C744;
}

.review-info {
  padding: 5%;
  background-color: #051A42;
  color: #F4C744;
  border: solid #F4C744;
  border-radius: 15px;
  margin: 3%;
}

.review-info h4 {
  width: fit-content;
  padding: 2%;
  display: inline;
  background-color: #0D2867;
  color: #f4d247;
  border-radius: 15px;
}

.venue-photo {
  width: 300px;
  height: auto;
}

.venue-photo-wrap {
  display: inline-block;
  justify-content: center;
  align-items: center;
  justify-items: center;
  text-align: center;
  border: 10px solid #051A42;
  background-color: #051A42;
  margin-right: 2%;
}

.establishment-show {
  text-align: center;
}

.establishment-show-name {
  font-size: 45px;
}

.establishment-info-grid {
  justify-items: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-right: 2%;
  margin-left: 2%;
}

.establishment-info-image {
  width: 80%;
  max-height: 90%;
  border: 10px solid #061A43;
}

.establishment-first-cell {
  background-color: #051A42;
  padding: 3%;
  border: solid #F4C744;
  border-radius: 15px;
  width: 80%;
  max-height: 70%;
}


@media (max-width: 695px) {
  .establishment-show {
    margin-top: 5%;
  }
  #establishment-back-button {
    position: inherit;
  }
}

@media (max-width: 767px) {
  .establishment-info-image {
    height: 200px;
  }
  .establishment-info-grid {
    display: block;
  }
  .second-grid {
    grid-template-columns: 1fr;
    row-gap: 1%;
  }
  #review-section {
    margin-right: 2%;
  }
  .venue-photo-wrap {
    margin-left: 2%;
  }
}

@media (max-width: 900px) {
  #establishment-back-button {
    width: 50px;
  }
  .establishment-first-cell {
    height: 500px;
  }
}

@media (max-width: 1080px) {
  .establishment-info-grid {
    display: grid; 
    grid-template-columns: 1fr;
  }
  #establishment-back-button {
    left: 5%;
  }
  .establishment-first-cell {
    height: 500px;
  }
  .establishment-second-cell h3 {
    width: 500px;
  }
}

@media (min-width: 1279px) {
  .establishment-info-image {
    height: 350px;
  }
  .establishment-first-cell {
    height: 600px;
  }
}

