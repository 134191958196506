.header {
  background-color: #001b46;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.nav {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  font-size: 25px;
  font-weight: bold;
  padding-top: 10px;
  font-weight: unset;
  z-index: 101;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.show {
  display: block;
}

.hide {
  display: none;
}

#nav-loggedin {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  font-size: 25px;
  font-weight: bold;
  padding-top: 10px;
  font-weight: unset;
  z-index: 101;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.nav a:link {
  text-decoration: none;
  text-align: center;
}

.nav-logo {
  padding-left: 20px;
  padding-bottom: 15px;
  transition: filter 1s;
}

.nav-logo:hover {
  filter: drop-shadow(0px 0px 15px #fdc500);
  transition: filter 1s;
}

.nav-link {
  padding-top: 2%;
  font-weight: bold;
}

.trending-link {
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .nav {
    font-size: 18px;
  }
  #nav-loggedin {
    font-size: 18px;
  }
  .nav-link {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .nav-logo {
    height: 35px;
    margin-top: 5px;
    margin-left: 5px;
  }
}

@media screen and (max-width: 690px) {
  .nav {
    font-size: 16px;
  }
  .nav-link {
    padding-bottom: 15px;
  }
  .nav {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3);
  }
  #login-wrapper {
    text-align: center;
    padding-top: 0px;
    padding-right: 0px;
    padding-top: 0px;
  }
  .nav-logo {
    margin-left: 0px;
    padding-bottom: 0px;
    height: 25px;
  }
  .about-link {
    padding-top: 0px;
    text-align: center;
  }
  #nav-loggedin {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(5, 1.5em);
    font-size: 16px;
  }
}
