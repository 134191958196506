.edit-profile {
  text-align: center;
  margin-left: 15%;
  margin-right: 15%;
}

.edit-profile h4 {
  margin: auto;
  width: 300px;
}

.bold {
  font-weight: bold;
}

.info-wrap {
  border: 1px solid #f3c743;
  border-radius: 15px;
  background-color: #061a43;
  text-align: center;
  margin: auto;
  margin-top: 3%;
  margin-bottom: 3%;
  width: 60%;
  padding: 3%;
}

.input-label-wrap {
  display: block;
  margin-top: 3%;
  justify-content: center;
}

.input-label-wrap label {
  display: inline-block;
  font-weight: bold;
  width: 5%;
  margin-right: 5%;
}

.input-label-wrap input {
  border-radius: 5px;
  width: 50%;
  margin-left: 7%;
  margin-right: 7%;
}

.clicked {
  background-color: #061a43;
  color: #fdc500;
  border: 5px solid #fdc500;
  padding: 10px 10px;
}

#bev-flavors {
  margin-top: 20%;
  font-weight: bold;
  text-decoration: underline;
}

#not-verified {
  color: red;
}

#not-verified:hover {
  cursor: pointer;
  text-decoration: underline;
}

#verified {
  color: green;
}

.delete-button:hover {
  background-color: #d00;
  color: #fff;
}
