#index-image {
  height: 200px;
  width: auto;
  max-width: 250px;
}

.one-establishment {
  width: 325px;
  text-align: center;
  box-shadow: 4px 4px 4px #061a43;
  border-radius: 15px;
  padding: 5px;
}

.establishment-header {
  text-align: center;
}

.establishment-name {
  font-size: 30px;
}

.establishment-name:hover {
  text-decoration: underline;
}

.establishment-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  margin: 2em 2em;
  justify-content: center;
  justify-items: center;
}

.establishment-grid a {
  text-decoration: none;
}

#index-button {
  position: absolute;
  left: 10%;
}


@media (min-width: 687px) {
  #index-button {
    position: absolute;
    top: 15%;
  }
}

@media (min-width: 767px) and (max-width: 799px) {
  #index-button {
    left: 0;
  }
}

@media (min-width: 800px) and (max-width: 874px) {
  #index-button {
    left: 1%;
  }
}

@media (min-width: 875px) and (max-width: 980px) {
    #index-button {
      left: 5%;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1439px) {
  .establishment-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 768px) and (max-width: 1099px) {
  .establishment-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 767px) {
  .establishment-grid {
    grid-template-columns: repeat(1, 1fr);
    margin: 2em 0.5em;
  }
  #index-button {
    position: relative;
    margin-left: 0%;
  }
}
